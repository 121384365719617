<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class=" d-sm-flex justify-content-between align-items-center pb-1  ">
          <h2 class="pt-2 pt-md-2 pt-xl-0">Expense Create</h2>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Category</label>
                    <VueMultiselect v-model="selectedExpenseType" class="multiselect-blue" :options="expenseTypes"
                                    :close-on-select="true" placeholder='Select category' label="name" track-by="name"
                                    :show-labels="false"/>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Amount</label>
                    <input v-model="putExpenseData.amount" type="number" class="form-control"
                           placeholder="Enter amount $">
                  </div>
                  <div class="text-danger" v-if="errors.amount">{{ errors.amount }}</div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1 ">
                      <div class="w-100 ">
                        <DatePicker v-model="putExpenseData.date" :update-on-input="true"
                                    :masks="{input: ['DD MMMM YYYY']}"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.date">{{ errors.date }}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 ">
                <div class="form-group">
                  <div class="">
                    <label>Note</label>
                    <textarea v-model="putExpenseData.note" class="form-control"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 ">
                <div class="d-flex justify-content-end">
                  <button @click="updateSingleExpense" type="button" class="btn btn-primary update-todo px-4">Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {DatePicker} from "v-calendar";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    AppLayout,
    DatePicker
  },
  mixins: [ShowToastMessage],
  name: "ExpenseEdit",
  data() {
    return {
      initialExpenseData: {
        amount: '',
        note: '',
        expense_type_id: '',
        date: ''
      },
      putExpenseData: {
        amount: '',
        note: '',
        expense_type_id: '',
        date: ''

      },
      getExpenseParams: {
        with_relation: ['expenseType', 'createdBy']
      },
      expenseId: '',
      selectedExpenseType: {
        value: '',
        name: 'None',
      },
      errors: {
        amount: '',
        date: '',
      }

    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      expense: 'appExpenses/expense',

    }),
    isExpenseDataChanged() {
      return !((this.initialExpenseData.amount === this.putExpenseData.amount) &&
          (this.initialExpenseData.notes === this.putExpenseData.notes) &&
          (this.initialExpenseData.expense_type_id === this.putExpenseData.expense_type_id));
    },
    expenseTypes() {
      let types = this.$store.getters['appExpenseTypes/expenseTypes'].map((type) => {
        let id = type.id;
        let name = type.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...types];
    },
  },
  watch: {
    selectedExpenseType() {
      this.putExpenseData.expense_type_id = this.selectedExpenseType.value;
    }
  },
  methods: {
    ...mapActions({
      getExpense: 'appExpenses/getExpense',
      putExpense: 'appExpenses/putExpense',
      resetExpense: 'appExpenses/resetExpense',
      getExpenseTypes: 'appExpenseTypes/getExpenseTypes'
    }),
    async getSingleExpense(id) {
      const paramObj = {
        id: id,
        params: this.getExpenseParams,
      };
      await this.getExpense(paramObj).then((response) => {
        if (response.status === 200) {
          //assigning Ids
          this.expenseId = this.expense.id;
          //expense types model
          if (this.expense.expense_type && this.expense.expense_type.id) {
            let typeIndex = this.expenseTypes.findIndex(item => item.value === this.expense.expense_type.id);
            if (typeIndex !== -1) {
              let type = this.expenseTypes[typeIndex];

              this.selectedExpenseType.value = type.value;
              this.selectedExpenseType.name = type.name;
            }
          }

          this.initialExpenseData = {
            amount: this.expense && this.expense.amount ? this.expense.amount : '',
            note: this.expense && this.expense.note ? this.expense.note : '',
            date: this.expense && this.expense.date ? this.expense.date : '',
            expense_type_id: this.selectedExpenseType.value,

          };
          this.putExpenseData = {
            amount: this.expense && this.expense.amount ? this.expense.amount : '',
            note: this.expense && this.expense.note ? this.expense.note : '',
            date: this.expense && this.expense.date ? this.expense.date : '',
            expense_type_id: this.selectedExpenseType.value,
          };
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    async updateSingleExpense() {
      if (this.isExpenseDataChanged) {
        const expensePramObj = {
          id: this.expenseId,
          data: this.putExpenseData,
        };
        await this.putExpense(expensePramObj).then((response) => {
          if (response.status === 200) {
            let toastObj = {message: 'Expense related info has Updated', type: 'success'};
            this.showToastMessage(toastObj);


          }
          this.errors.amount = response.errors && response.errors.amount ? response.errors.amount[0] : '';
          this.errors.date = response.errors && response.errors.date ? response.errors.date[0] : '';
          if (response.message) {
            this.showToastMessage(response)
          }
        });
      }
      if (this.errors.amount || this.errors.date) {
        return;
      }
      await this.$router.replace({
        name: 'app.account.expense.list',
      });
    },
  },

  mounted() {
    const routeParams = this.$route.params;
    this.getExpenseTypes();
    this.getSingleExpense(routeParams.id);

  },
  beforeUnmount() {
    this.resetExpense();
  },
}
</script>

<style scoped>

</style>
